import { Chip, ListSubheader, MenuItem, Select, SelectProps, Stack } from '@mui/material';
import { Readiness, StatementType, StatementTypeReadiness } from '../api';

function statementTypeReadiness(statementType: StatementType) {
  if (StatementTypeReadiness[statementType] === Readiness.EXPERIMENTAL) {
    return <Chip label='Experimental' color='warning' size='small' sx={{ cursor: 'pointer' }} />;
  }

  return null;
}

export type StatementTypeSelectProps = SelectProps & {
  statementType: StatementType;
  onStatementTypeChange: (statementType: StatementType) => void;
};

export function StatementTypeSelect({ statementType, onStatementTypeChange, ...props }: StatementTypeSelectProps) {
  return (
    <Select {...props} value={statementType} onChange={(event) => onStatementTypeChange(event.target.value as StatementType)}>
      <ListSubheader>PDF</ListSubheader>
      <MenuItem value={StatementType.CIBC_PDF}>
        <Stack direction='row' alignItems='center' spacing={2} justifyContent='space-between' width='100%'>
          <span>CIBC</span>
          {statementTypeReadiness(StatementType.CIBC_PDF)}
        </Stack>
      </MenuItem>
      <MenuItem value={StatementType.CIBC_VISA_PDF}>
        <Stack direction='row' alignItems='center' spacing={2} justifyContent='space-between' width='100%'>
          <span>CIBC Visa</span>
          {statementTypeReadiness(StatementType.CIBC_VISA_PDF)}
        </Stack>
      </MenuItem>
      <MenuItem value={StatementType.RBC_CANADA}>
        <Stack direction='row' alignItems='center' spacing={2} justifyContent='space-between' width='100%'>
          <span>RBC Canada</span>
          {statementTypeReadiness(StatementType.RBC_CANADA)}
        </Stack>
      </MenuItem>
      <MenuItem value={StatementType.RBC_US}>
        <Stack direction='row' alignItems='center' spacing={2} justifyContent='space-between' width='100%'>
          <span>RBC US</span>
          {statementTypeReadiness(StatementType.RBC_US)}
        </Stack>
      </MenuItem>
      <MenuItem value={StatementType.RBC_VISA_USD}>
        <Stack direction='row' alignItems='center' spacing={2} justifyContent='space-between' width='100%'>
          <span>RBC Visa USD</span>
          {statementTypeReadiness(StatementType.RBC_VISA_USD)}
        </Stack>
      </MenuItem>
      <MenuItem value={StatementType.RBC_VISA_CAD}>
        <Stack direction='row' alignItems='center' spacing={2} justifyContent='space-between' width='100%'>
          <span>RBC Visa CAD</span>
          {statementTypeReadiness(StatementType.RBC_VISA_CAD)}
        </Stack>
      </MenuItem>
      <MenuItem value={StatementType.RBC_OPERATING_LINE}>
        <Stack direction='row' alignItems='center' spacing={2} justifyContent='space-between' width='100%'>
          <span>RBC Operating Line</span>
          {statementTypeReadiness(StatementType.RBC_OPERATING_LINE)}
        </Stack>
      </MenuItem>
      <MenuItem value={StatementType.AMEX_CAD}>
        <Stack direction='row' alignItems='center' spacing={2} justifyContent='space-between' width='100%'>
          <span>Amex CAD</span>
          {statementTypeReadiness(StatementType.AMEX_CAD)}
        </Stack>
      </MenuItem>
      <MenuItem value={StatementType.AMEX_USD}>
        <Stack direction='row' alignItems='center' spacing={2} justifyContent='space-between' width='100%'>
          <span>Amex USD</span>
          {statementTypeReadiness(StatementType.AMEX_USD)}
        </Stack>
      </MenuItem>
      <MenuItem value={StatementType.LOOP_ACCOUNT}>
        <Stack direction='row' alignItems='center' spacing={2} justifyContent='space-between' width='100%'>
          <span>Loop Account</span>
          {statementTypeReadiness(StatementType.LOOP_ACCOUNT)}
        </Stack>
      </MenuItem>
      <MenuItem value={StatementType.WEALTHSIMPLE}>
        <Stack direction='row' alignItems='center' spacing={2} justifyContent='space-between' width='100%'>
          <span>Wealthsimple</span>
          {statementTypeReadiness(StatementType.WEALTHSIMPLE)}
        </Stack>
      </MenuItem>

      <ListSubheader>CSV</ListSubheader>
      <MenuItem value={StatementType.FLOAT_CSV}>
        <Stack direction='row' alignItems='center' spacing={2} justifyContent='space-between' width='100%'>
          <span>Float</span>
          {statementTypeReadiness(StatementType.FLOAT_CSV)}
        </Stack>
      </MenuItem>
      <MenuItem value={StatementType.LOOP}>
        <Stack direction='row' alignItems='center' spacing={2} justifyContent='space-between' width='100%'>
          <span>Loop</span>
          {statementTypeReadiness(StatementType.LOOP)}
        </Stack>
      </MenuItem>
      <MenuItem value={StatementType.STRIPE_CSV}>
        <Stack direction='row' alignItems='center' spacing={2} justifyContent='space-between' width='100%'>
          <span>Stripe</span>
          {statementTypeReadiness(StatementType.STRIPE_CSV)}
        </Stack>
      </MenuItem>
      <MenuItem value={StatementType.VAULT_CSV}>
        <Stack direction='row' alignItems='center' spacing={2} justifyContent='space-between' width='100%'>
          <span>Vault</span>
          {statementTypeReadiness(StatementType.VAULT_CSV)}
        </Stack>
      </MenuItem>
      <MenuItem value={StatementType.WISE}>
        <Stack direction='row' alignItems='center' spacing={2} justifyContent='space-between' width='100%'>
          <span>Wise</span>
          {statementTypeReadiness(StatementType.WISE)}
        </Stack>
      </MenuItem>
      <MenuItem value={StatementType.OTTER_CSV}>
        <Stack direction='row' alignItems='center' spacing={2} justifyContent='space-between' width='100%'>
          <span>Otter CSV</span>
          {statementTypeReadiness(StatementType.OTTER_CSV)}
        </Stack>
      </MenuItem>
    </Select>
  );
}
